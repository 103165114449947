import './Deck.css';

const Deck = () => {
    return (
        <div className='card-back'>
        </div>
    );
}

export default Deck;
