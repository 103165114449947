const config = {
    apiKey: "AIzaSyBIrBy4hAi7BJyObpdM7nEg-Z514Y6pyXE",
    authDomain: "hactually-holdem.firebaseapp.com",
    databaseURL: "https://hactually-holdem-default-rtdb.firebaseio.com",
    projectId: "hactually-holdem",
    storageBucket: "hactually-holdem.appspot.com",
    messagingSenderId: "18505024517",
    appId: "1:18505024517:web:a43b2e0d383204b1e9c86c",
    measurementId: "G-KL9THYD2YJ",
}

export default config;